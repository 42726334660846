/**
 * 将时间解析为字符串
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * 判断对象 某些字段是否为空。 深度检查
 * @param {*} path 路径  对象的字段路径
 * @param {*} object 目标对象
 * demo : //在浏览器和nodejs中都能正确判断
  if(isKeyExists('user.house.dog', user)) {
    console.log('hello dog');
  }
 */
export function isKeyExists(path, object) {
  if (!object || !path) {
    return false;
  }
  let obj = Object.assign({}, object);
  if (!obj) {
    return false;
  }
  //将传入的对象路径字符串拆分为数组
  let pathList = path.split(".");
  let key;
  for (let i = 0; (key = pathList[i]); i++) {
    if (!obj[key]) {
      return false;
    }
    obj = obj[key];
  }
  return true;
}

/**
 * 获取对象 深度字段的值 如果某一个为空 就返回空串
 */
export function getKeyValueByExists(path, object) {
  if (!object || !path) {
    return "";
  }
  let obj = Object.assign({}, object);
  if (!obj) {
    return "";
  }
  //将传入的对象路径字符串拆分为数组
  let pathList = path.split(".");
  let key;
  for (let i = 0; i < pathList.length; i++) {
    key = pathList[i];
    if (!obj[key]) {
      return "";
    }
    obj = obj[key];
  }
  return obj;
}

/**
 * 获取图片数组 （商品、订单、购物车）
 * @param imgs 字符串
 */
export function getImgArr(images) {
  // console.log("getImgArr", images);
  return images ? images.split(",").filter(img => img) : [];
}

/**
 * 克隆对象  返回被克隆的对象
 * @param obj 被克隆的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 合并两个对象，使最后一个对象优先
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (!target || !source) {
    return null;
  }
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * 判断 数组 是否为空  长度为0也是空
 * @param array 数组
 */
export function arrayIsEmpty(array) {
  return !arrayIsNotEmpty(array);
}
/**
 * 判断 数组 是否 不为空  长度为0也是空
 * @param array 数组
 */
export function arrayIsNotEmpty(array) {
  return array && array instanceof Array && array.length > 0;
}

/**
 * 根据rgb,计算rgb亮度
 *
 *
 */
export function getBrightnessOfColor(color) {
  const hexToRgb = hexColor => {
    return parseInt(hexColor, 16).toString();
  };
  // FFFFFF 情况
  if (color.indexOf("#") === -1 && color.length === 6) {
    color = "#" + color;
  }
  // #fff 情况
  else if (color.indexOf("#") >= 0 && color.length === 4) {
    color = color + color.substr(1, 3);
  }
  const stringColor = color.toUpperCase().replace(/\s/g, "");
  const rgbaReg = /(RGB)A?\((\d*),(\d*),(\d*),?(\d*)?\)/;
  //rgba 情况
  if (/#.*/.test(stringColor)) {
    if (stringColor >= "#000000" && stringColor <= "#FFFFFF") {
      let useColorValue = stringColor.slice(1);
      let rColor = useColorValue.slice(0, 2);
      let gColor = useColorValue.slice(2, 4);
      let bColor = useColorValue.slice(4);
      let rColorValue = hexToRgb(rColor);
      let gColorValue = hexToRgb(gColor);
      let bColorValue = hexToRgb(bColor);
      let lightness =
        rColorValue * 0.299 + gColorValue * 0.587 + bColorValue * 0.114;
      return { lightness, rgbArr: [rColorValue, gColorValue, bColorValue] };
    } else {
      console.log("rgb16 invalid!");
      return {};
    }
  } else if (rgbaReg.test(stringColor)) {
    const rgbArr = rgbaReg.exec(stringColor).slice(2);
    if (
      rgbArr.every(cur => {
        const curNum = Number(cur);
        return curNum >= 0 && curNum <= 255;
      })
    ) {
      let lightness = rgbArr[0] * 0.299 + rgbArr[1] * 0.587 + rgbArr[2] * 0.114;
      return { lightness, rgbArr };
    } else {
      console.log("rgb(a) invalid!");
      return {};
    }
  } else {
    console.log("color invalid!");
    return {};
  }
}

/**
 * 根据颜色自动生成相对颜色 (亮度小于大于128 权重+-25)
 *
 */
export function getColorAutomatically(color, power) {
  const defaultPower = power || 15;
  const { lightness, rgbArr } = getBrightnessOfColor(color);
  if (lightness === undefined) {
    return color;
  }
  //深色
  return (
    `rgb${rgbArr.length === 4 ? "a" : ""}(` +
    rgbArr
      .map((cur, index) => {
        let value =
          Number(cur) + (lightness < 128 ? defaultPower : -defaultPower);
        value = value > 255 ? 255 : value;
        value = value < 0 ? 0 : value;
        return index === 3 ? cur : value;
      })
      .join(",") +
    ")"
  );
}

//浮点数 保持精度 加
export function floatAdd() {
  function _add(arg1, arg2) {
    arg1 = arg1 || 0;
    arg2 = arg2 || 0;
    let r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (parseInt(arg1 * m) + parseInt(arg2 * m)) / m;
  }
  return Array.prototype.reduce.call(arguments, (result, arg) => {
    return _add(result, arg);
  });
}

//浮点数 保持精度 减
export function floatSub(arg1, arg2) {
  arg1 = arg1 || 0;
  arg2 = arg2 || 0;
  let r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//浮点数 保持精度 乘
export function floatMul(arg1, arg2) {
  arg1 = arg1 || 0;
  arg2 = arg2 || 0;
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {return}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {return}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

//浮点数 保持精度 除
export function floatDiv(arg1, arg2) {
  arg1 = arg1 || 0;
  if (!arg2) {
    console.error("除数不能为0");
    return;
  }
  let t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {return}
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {return}

  r1 = Number(arg1.toString().replace(".", ""));

  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

export function deepCopy(o, callback) {
  const isObject = o instanceof Object;
  const isArray = o instanceof Array;
  if (!isObject) {
    return (callback && callback(o)) || o;
  }
  const k = isArray ? [] : {};
  for (const n in o) k[n] = deepCopy.call(this, o[n], callback);
  return k;
}
// Jquery.text()方法简单实现 @:string | HtmlElement
export function readText(elem) {
  if (!elem) {
    return "";
  }
  const isElemString = typeof elem === "string";
  if (isElemString) {
    const div = document.createElement(`div`);
    div.insertAdjacentHTML("beforeend", elem);
    elem = div;
  }
  let node,
    ret = "",
    i = 0,
    nodeType = elem.nodeType;
  //如果selector是类的话，会有多个目标元素，此时需要分别单个循环
  //比如document.querySelectorAll('.divOne').nodeType ->undefined
  if (!nodeType) {
    while ((node = elem[i++])) {
      //单个获取
      ret += readText(node);
    }
  }
  //元素节点，文档节点，文档碎片
  else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
    //如果目标元素的内容是文本，则直接返回
    if (typeof elem.textContent === "string") {
      /*jQuery没有用innerText获取文本的值，http://bugs.jquery.com/ticket/11153，
      大概就是在IE8中新节点插入会保留所有回车。
      所以jQuery采用了textContent获取文本值，
      textContent本身是dom3规范的，可以兼容火狐下的innerText问题。*/
      return elem.textContent;
    }
    //如果节点内容不是文本，则循环子节点，并依次获取它们的文本节点
    else {
      for (elem = elem.firstChild; elem; elem = elem.nextSibling) {
        ret += readText(elem);
      }
    }
  }
  //文本节点、一个文档的CDATA部分（没遇到过这个）
  else if (nodeType === 3 || nodeType === 4) {
    //返回节点值
    return elem.nodeValue;
  }
  //nodeType：注释节点 8，处理指令 7
  //text()方法不处理这两个类型节点
  return ret;
}
