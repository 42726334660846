import axios from "axios";
import {
    deepCopy
} from "@/api";

let baseURL = null;
switch (process.env.NODE_ENV) {
    case "production":
        baseURL = "//www.boostor.com.cn/api/";
        break;
    case "test":
        baseURL = "//tianfu-index-api.homyi.boostor.com.cn/";
        break;
    case "development":
    default:
        baseURL = "//188.188.8.77:8080/";
        break;
}
console.log(baseURL);

const request = axios.create({
    baseURL: baseURL,
    timeout: 30000
});
// request 请求前
request.interceptors.request.use(async config => {
    //   config.headers["token"] = await getToken();
    return config;
});
console.log(process.env.NODE_ENV);
// request 相应后
request.interceptors.response.use(
    function(res) {
        // 对响应数据做点什么
        // if (res.data.code) {
        //   return Promise.reject(res);
        // }
        const data = deepCopy(res.data, s =>
            s === "string" ? s.replace(/http:\/\//g, "//") : s
        );
        return data;
    },
    function(error) {
        // 对响应错误做点什么
        console.log(error.response);
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    break;
                case 403:
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);
export default request;